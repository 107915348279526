<template>
  <div>
    <a-card title="套餐管理">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-space>
            <a-input v-model="searchForm.search" placeholder="编号、名称" allowClear />
            <a-button type="primary" @click="init()">查询</a-button>
            <a-button @click="onReset">重置</a-button>
          </a-space>
        </a-col>
        <a-col :span="12" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="$refs.EditComboForm.addModal()" icon="plus">新建套餐</a-button>
        </a-col>
      </a-row>
      <a-table
        :rowKey="record => record.id"
        :columns="columns"
        :loading="loading"
        :dataSource="dataSet"
        :pagination="pagination"
        @change="tableChange"
        style="margin-top: 15px;"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="$refs.EditComboForm.editModal(record.id)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除吗?" @confirm="onDelete(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <EditComboForm ref="EditComboForm" @ok="onReset()" />
  </div>
</template>

<script>
import { columns } from '@/utils/maps/combo'
import { salePackageList, delSalePackage } from '@/api/sale'

export default {
  name: 'combo',
  components: {
    EditComboForm: () => import('./EditComboForm.vue')
    // FormModal: () => import('./FormModal.vue'),
  },
  data() {
    return {
      columns,
      dataSet: [],
      loading: false,
      searchForm: {},
      pagination: {}
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init(page, page_size) {
      this.loading = true;
      this.searchForm = {
        ...this.searchForm,
        page: page || 1,
        page_size: page_size || 10
      }
      if (!this.pagination.pageSize || !page || !page_size) {
        this.pagination = { current: 1, total: 0, pageSize: 10 }
      }
      this.getList()
    },
    getList() {
      salePackageList(this.searchForm).then(data => {
        const { results = {}, count = 0 } = data
        this.dataSet = results
        this.pagination.total = count
      }).finally(() => {
        this.loading = false
      })
    },
    tableChange(pagination) {
      this.pagination = { ...this.pagination, ...pagination }
      this.init(pagination.current, pagination.pageSize)
    },
    onReset() {
      this.searchForm = {}
      this.pagination = { ...this.pagination, current: 1, pageSize: 10 }
      this.init()
    },
    onDelete(param) {
      delSalePackage(param).then(res => {
        this.$message.success('删除成功')
        this.onReset()
      }).finally(() => {
        this.loading = false
      })
      console.log(param, '🚀 ~ 删除')
    }
  },
}
</script>

<style scoped>
</style>
