export const columns = [
  {
    title: '序号',
    align: 'center',
    dataIndex: 'index',
    customRender: (value, item, index) => index + 1,
  },
  {
    title: '套餐编号',
    align: 'center',
    ellipsis: true,
    dataIndex: 'number',
    customRender: text => text ?? '-'
  },
  {
    title: '套餐名称',
    align: 'center',
    ellipsis: true,
    dataIndex: 'package_name',
    customRender: text => text ?? '-'
  },
  {
    title: '包含产品数量',
    align: 'center',
    width: 120,
    dataIndex: 'total_quantity',
    customRender: text => text ?? '-'
  },
  {
    title: "备注",
    align: 'center',
    dataIndex: "status",
    customRender: text => text ?? '-'
  },
  {
    title: '操作',
    fixed: 'right',
    width: '120px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  },
]

export const columnsProd = [
  {
    title: '产品编号',
    align: 'center',
    dataIndex: 'goods_number',
    customRender: text => text ?? '-'
  },
  {
    ellipsis: true,
    width: '250px',
    title: '产品名称',
    dataIndex: 'goods_name',
    customRender: text => text ?? '-'
  },
  {
    title: '产品数量',
    width: '200px',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'prodNum' }
  },
  {
    title: '操作',
    width: 100,
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export const rules = {
  number: [{ required: true, message: '请填写套餐编号', trigger: 'change' }],
  package_name: [{ required: true, message: '请填写套餐名称', trigger: 'change' }],
  // production: [{ required: true, message: '请至少选择一个产品' }],
}

// export const dictionaryRules = {
//   name: [{ required: true, message: '请选择对应类型', trigger: 'change' }],
//   production: [{ required: true, message: '请选择对应产品', trigger: 'change' }],
// }


export const comboChooseColumns = [
  {
    title: '序号',
    align: 'center',
    width: '60px',
    dataIndex: 'index',
    customRender: (value, item, index) => index + 1,
  },
  {
    ellipsis: true,
    align: 'center',
    title: '套餐编号',
    dataIndex: 'number',
    customRender: text => text ?? '-'
  },
  {
    title: '套餐名称',
    align: 'center',
    dataIndex: 'package_name',
    customRender: text => text ?? '-'
  },
]
